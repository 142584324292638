import  React from 'react';
import persons from './menu/menuImg/persons.svg';
import contact from './menu/menuImg/contact.svg';
import home from './menu/menuImg/home.svg';
import PgButton from './menu/pgbtn';
import classes from './navbar.module.css'

const Navbar = () => {

    // change img to button
    return (
    <>
        <nav className={classes.title}>
                <PgButton name="Youth Public Speaking Association" to="/">
                    <img src={home} alt='YPSA'/>
                </PgButton>
    
                <div className={classes.icons}>
                    <ul>
                        <li>    
                            <PgButton to="/about">
                                <img src={persons} alt='our team'/>
                            </PgButton> 
                        </li>
                        <li>    
                            <PgButton to="/contact">
                                <img src={contact} alt='contact' />
                            </PgButton>  
                        </li>             
                    </ul>
                </div>
        </nav>
    </>
    );
};

export default Navbar;