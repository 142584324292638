import { useState, useRef } from "react";

// This hook is going to be able to make a input
const useInput = (validateValue) => {
    const [isVal, setIsVal] = useState(true);
    const [isTouched, setIsTouched] = useState(false);
    const enteredValueRef = useRef();

    const valueOnBlur = (event) => {
        setIsTouched(true);
        
        if (validateValue(enteredValueRef.current.value) && !isTouched) {
            setIsVal(false);
            return;
        }
        setIsVal(true);
    };

    const reset = () => {
        setIsVal(true);
        setIsTouched(false);
    };

    return ({enteredValueRef, isVal, valueOnBlur, reset});
};

export default useInput;