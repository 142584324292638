import React from 'react';
import help from './helping.png';
import sof from './sof.png';
import classes from './home.module.css';
import Hero from '../../components/hero/hero';
import PgButton from '../../components/header/menu/pgbtn';


const Home = (props) => {

  return (
    <div>
      <div className={classes.intro}>
        <h1 className={classes.hey}>Welcome to Youth Public Speaking Association!</h1>
      </div>
        
      <div className={classes.hero}>
        <Hero className={classes.container}>
         	<img src={help} alt="ypsahelping"/>
         	<div>
            <h1>Who Are We?</h1>
         		<p>Youth Public Speaking Association is determined to help children learn speaking skills to effectively communicate with other people. With the help of our verified tutors, we teach essential techniques to convey infromation through tone, facts, and other persuasive appeals. Most importantly, we teach kids how to express their thoughts and make them comfortable doing so as well.</p>
            	<PgButton to="/contact" className={classes.contact}>  
              		Sign Up Today!
            	</PgButton>
          	</div>
        </Hero> 
      </div>

      <div className={classes.hero}>
        <Hero className={classes.container}>
          <div>
            <h1>What Does YPSA Teach?</h1>
            <p>
            Youth Public Speaking Association works towards helping students learn the format of speeches. Once they learn how to format speeches properly, the students will be able to move on to practice speeches. We encourage them to try new things when practicing because that is the only way to learn. Over the course of six weeks, the students will learn essential persuasive tools such as how to use body language and voice modulation to emphasize key phrases within speeches. At the end of the six weeks, YPSA holds a friendly competition, called Speakers of the Future (SOF) where a guest speaker informs the students the usefulness of their own public speaking experience.  
            </p>
          </div>
          <img src={sof} alt={"Eileen"} width={800} height={500}/>
        </Hero>
      </div>
    </div>
  )
}

export default Home;