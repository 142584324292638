import { useState, React }from "react";
import { Link } from "react-router-dom";
import classes from './pgbtn.module.css';

const PgButton = (props) => {
        return (
            // make hover effect for all children of props
            <Link to={props.to} preventScrollReset={false}>
            <div className={props.className ? props.className : classes.button}>
                <button>
                    {props.children}
                </button>
            </div>
                
            </Link>
            
        );
}

export default PgButton;