import { useEffect, useState } from "react";

export const useWindowsize = () => {
    const [windowSize, setWindowSize] = useState([0, 0]);

    useEffect(() => {
    function handleResize() {
        setWindowSize([window.innerWidth, window.innerHeight])
    }

    window.addEventListener('resize', handleResize)
    handleResize()
    }, [])
    return windowSize;
}