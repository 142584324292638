import Navbar from './components/header/navbar';
import Home from './pages/home/home.js';
import About from './pages/about/About';
import Contact from './pages/contact/contact';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Footer from './components/footer/footer.js';
import classes from './app.module.css';

function App() {
  return (
    <div className={classes.for}>
    	<BrowserRouter>
    		<Navbar/>	
    		<Routes>
    		  	<Route path='' element={<Home/>} />
    		  	<Route path='/about' element={<About/>} />
    		  	<Route path='/contact' element={<Contact/>} />
    		</Routes>
			<Footer />
      	</BrowserRouter>
    </div>
  );
}

export default App;
