import React, { useState } from 'react'
import useInput from '../../hooks/use-input';
import classes from './ContactForm.module.css';
import emailjs from 'emailjs-com';

const ContactForm = (props) => {
    
    const {enteredValueRef: email, isVal: emailValid, valueOnBlur: emailBlur, reset: emailRes} = useInput((value)=> value.trim() === "");
    const {enteredValueRef: text, isVal: textValid, valueOnBlur: textBlur, reset: textRes} = useInput((value)=> value.trim() === "");
    const {enteredValueRef: name, isVal: nameValid, valueOnBlur: nameBlur, reset: nameRes} = useInput((value)=> value.trim() === "");
    const [isOther, setOther] = useState(false);

    const optionChanger = (event) => {
        event.preventDefault();
        if (event.target.value === "Other") {
            setOther(true);
        }

        else {
            setOther(false);
        }
      };
    
    const onsub = (event) => {

        event.preventDefault();      

        if (emailValid && nameValid) {
            emailjs.send('service_7f4ev4g', 'template_iwxki08', {
                from_name: name.current.value, 
                from_email: email.current.value, 
                subject: isOther ? event.target.subject1.value : event.target.subject.value,
                message: text.current.value
            }, 'a5HZlPFNImKVN-Dja')
            .then((result) => { 
                alert("Email sent successfully! If we do not respond within 48 hours please email us again directly to youthpsa22@gmail.com");
                emailRes();
                textRes();
                nameRes();
            }, (error) => {
                console.log(error.text);
            });
        }
                
    };

    return (
        <form className={classes.submit} onSubmit={onsub}>
            <section>
                <label>Full Name</label>
                <input placeholder="John Doe" ref={name} onBlur={nameBlur} type="text" required/>
                <label>Email</label>
                <input ref={email} onBlur={emailBlur} type="email" placeholder='youremail@email.com'required/>
            </section>

            <section>
                <section> 
                    <label>Subject</label>                   

                    <select name='subject' onChange={optionChanger}>
                        <option>Interest</option>
                        <option>Questions</option>
                        <option>Other</option>
                    </select>
                    
                    {isOther && 
                    <section>
                        <label>Subject</label>
                        <input type="text" name="subject1" placeholder='Other'/>
                    </section>
                    }
                
                </section>
            </section>

        
        <section>
            <label>Message</label>
            <textarea placeholder='Your Message' ref={text} cols='40' rows='5' required/>
        </section>


            <button type='submit'>Send Email</button>
        </form>
  );
}

export default ContactForm;