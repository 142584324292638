import React from 'react'
import classes from './footer.module.css';
import PgButton from '../header/menu/pgbtn';
const Footer = () => {
  return (
    <div className={classes.ftr}>
      <p>
      youthpsa22@gmail.com
      </p>
      <ul>
        <li>
          <PgButton to="/" className={classes.links}>
            Home
          </PgButton>
        </li>
        <li>    
          <PgButton to="/about" className={classes.links}>
            About
          </PgButton> 
        </li>
        <li>
          <PgButton to="/contact" className={classes.links}>
            Contact
          </PgButton>  
        </li>
      </ul>


    </div>
  )
}

export default Footer;