import React from 'react'
import Hero from '../../components/hero/hero';
import classes from './about.module.css';
import pic from './YPSA team.png';
import { useWindowsize } from '../../hooks/useWindowSize';


const About = () => {

    
  const TEAM = [
    {img: '/prathiv.jpg', name: 'Prathiv Raj Ramesh Babu', position: 'CEO', description: 'Prathiv Raj Ramesh Babu is a Senior at High School South. He has been participating in public speaking competitions since a young age. As he understands the value of public speaking, he hopes to continue helping students develop the same vital skills that he learned in communication and english workshops.'},
    {img: '/haley.png', name: 'Haley Tran', position: 'COO', description: 'Haley Tran is a Senior at High School South. She has a passion for law and current events, and is a leader of her school\'s Model UN Team. Outside of school, she likes to read in her free time.'},
    {img: '/veer.png', name: 'Veer Sehgal', position: 'CFO', description: 'Veer Sehgal is a Senior at High School South. He is heavily involved in Competitive Public Speaking through Model UN along with leadership roles in Robotics and Cross Country.'},
    {img: '/shritan.jpg', name: "Shritan Gopu", position: "CTO", description: 'Shritan Gopu is a current junior at WWP South who loves to explore the world around him. He is usually playing tennis, working on his racing skills, or eating too much. Public speaking inspired Shritan to acheive more than he currently could and used YPSA to share his experience with up and coming students.'},
    {img: '/dima.png', name: 'Dima Al-Quzwini', position: 'Co-Director of Operations', description: 'Dima Al-Quzwini is a high school junior at West Windsor Plainsboro High School South interested in international relations and policy. She joined YPSA to give students the opportunity to discover their voice in a welcoming and comfortable environment. In her free time, she enjoys listening to music, rewatching Suits, and rereading the Harry Potter books'},
    {img: '/rohit.png', name: 'Rohit Datta', position: 'Co-Director of Operations', description: 'Rohit Datta is a high school junior at West Windsor-Plainsboro High School South intersted in government and public policy. By joining YPSA, he hopes to help the next generation prosper in public speaking. When not in YPSA, you can catche him spending time with his beloved dog.'}, 
    {img: '/amritha.jpeg', name: 'Amrita Choulur', position: 'Tutor', description: 'Amrita Choulur is a junior at WWP South. She participates and loves public speaking for instance she is involved in her model united nations team. She joined YPSA since she wants to teach kids about a skill that she is passionate about and wants to help them grow their confidence through public speaking just like she did.'},
    {img: '/izza.png', name: 'Izza Mudasir', position: 'Tutor', description: 'Izza Mudassir is a current junior at WWP South. In her spare time, she can often be found baking or spending quality time with her friends. Her enthusiasm for public speaking and teaching led her to join YPSA, as it allowed her to combine two of her favorite activities.'},
  ];


  const [width, height] = useWindowsize();

  return (
    <div>
      <div className={classes.intro}>
        <h1>Meet The Team Behind It All</h1>
        <img src={pic} alt="something"/>
      </div>

      <div className={classes.people}>
        <ul>
            {TEAM.map((value) => {
              return (
                <li>
                        <Hero className={classes.information}>
                            <img src={ "./pics" + value.img} alt={value.name}/>
                            <h2>{value.name}</h2>
                            <h3>{value.position}</h3>
                            <p>{value.description}</p>
                        </Hero>
                    </li>
                )
              })}
        </ul>
      </div>  
        {/* <Footer/> */}
    </div>
  );
}

export default About;