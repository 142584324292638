import React from 'react'
import classes from './contact.module.css';
import ContactForm from './ContactForm';
import Footer from '../../components/footer/footer';
import Hero from '../../components/hero/hero';


const Contact = (props) => {
  return (
    <div>
  <div className={classes.page}>
      <h1>Send an Email to us!</h1>
      <Hero>
        <ContactForm />
      </Hero>

    </div>
  </div>
  );
}

export default Contact;